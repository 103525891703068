import type { ReactNode } from 'react';
import React, { memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import type { IntlShape } from 'react-intl-next';
import { useIntl } from 'react-intl-next';

import type { DocNode } from '@atlaskit/adf-schema';
import { EditorCardProvider } from '@atlaskit/editor-card-provider';
import type { ExtensionHandlers } from '@atlaskit/editor-common/extensions';
import type { ProviderName } from '@atlaskit/editor-common/provider-factory';
import { ProviderFactory } from '@atlaskit/editor-common/provider-factory';
import { EmojiResource } from '@atlaskit/emoji';
import { SmartCardProvider } from '@atlaskit/link-provider';
import type { RendererAppearance, RendererProps, RenderOutputStat } from '@atlaskit/renderer';
import { RendererWithAnalytics as AkRenderer } from '@atlaskit/renderer';
import type { ObjectKey, TaskState } from '@atlaskit/task-decision';
import { fg } from '@atlaskit/platform-feature-flags';

import { isEmbeddedConfluence_DO_NOT_USE } from '@atlassian/embedded-confluence/isEmbeddedConfluence';

import { getRendererSmartButtonExtensionHandlers } from '@confluence/automation-extensions';
import { PageLoadMark } from '@confluence/browser-metrics';
import { ContentRefetchHandler } from '@confluence/content-body';
import { isPageSSRd, useStoreOptions } from '@confluence/content-smartlinks';
import { CoreInvitesContext } from '@confluence/core-invites-provider';
import { getRendererCustomSitesExtensionHandlers } from '@confluence/custom-sites-extensions/entry-points/getRendererCustomSitesExtensionHandlers';
import { useEditorFeatureFlags } from '@confluence/editor-features/entry-points/useEditorFeatureFlags';
import { useProfilecardProvider } from '@confluence/editor-features/entry-points/useProfilecardProvider';
import { Attribution } from '@confluence/error-boundary';
import { ExternalShareContext } from '@confluence/external-share-context';
import { RendererAnalyticsListener } from '@confluence/fabric-analytics-listeners';
import type { CreateEmojiProviderParametersType } from '@confluence/fabric-emoji-support';
import { createEmojiProviderParameters } from '@confluence/fabric-emoji-support';
import { getSmartCardRenderers } from '@confluence/fabric-extension-lib';
import type { TaskAction, ProfileCardProvider } from '@confluence/fabric-media-support';
import {
	ConfluenceTaskDecisionProvider,
	createMentionsConfig,
	createRendererMediaProvider,
	getMediaFeatureFlags,
	useMediaSSRModeAndAuthConfig,
	TaskBatcher,
} from '@confluence/fabric-media-support';
import { getEmojiConfig, getEmojiProvider } from '@confluence/fabric-providers';
import type { FlagsStateContainer } from '@confluence/flags';
import { useViewUserProfile } from '@confluence/global-operations';
import { useSmartCardProviderFeatureFlags } from '@confluence/linking-platform';
import { getLogger } from '@confluence/logger';
import { getMonitoringClient } from '@confluence/monitoring';
import { usePageContentId, useContentType, usePageSpaceKey } from '@confluence/page-context';
import { FeedFollowErrorDisplay, useFeedFollow } from '@confluence/profile';
import { useRouteActions } from '@confluence/route-manager/entry-points/RouteState';
import { useNavigationPolicy } from '@confluence/route-manager/entry-points/useNavigationPolicy';
import type { FeatureFlagsType } from '@confluence/session-data';
import { useSessionData } from '@confluence/session-data';
import { expVal } from '@confluence/feature-experiments';
import { useRenderServerPlaceholder } from '@confluence/ssr-utilities';

import {
	registerGlobalMediaViewedListener,
	removeGlobalMediaViewedListener,
} from './AttachmentEvents';
import { batchTaskUpdate } from './batchTaskUpdate';

export const ANALYTICS_EVENT_SEVERITY_TRACKING = {
	enabled: true,
	severityDegradedThreshold: 4747,
	severityNormalThreshold: 3775,
};

const DEFAULT_TASK_UPDATE_INTERVAL_MS = 3000;
const logger = getLogger('ADFRenderer');
export const UNSUPPORTED_CONTENT_THRESHOLDS = {
	enabled: true,
	thresholds: {
		blocking: 1.5,
		degraded: 1,
	},
};

export interface ADFRendererProps extends RendererProps {
	getExtensionHandlers?: (
		setLegacyRendererFlag?: ((extensionKey?: string) => void) | undefined,
	) => ExtensionHandlers;
	getDataProviders?: () => ProviderFactory;
	onRendered?: () => void;
	contentId?: string;
	contentType?: string;
	appearance?: RendererAppearance;
	portal?: HTMLDivElement;
	onComplete?: (stats: any) => void;
	renderWhenReady?: () => ReactNode;
	allowStickyHeaders?: boolean;
	queryHash?: string;
	isTruncatedContent?: boolean;
	maxHeight?: number;
	fadeOutHeight?: number;
	mediaToken?: string;
	isContentHeaderFixedAtTop?: boolean;
}

interface ADFRendererComponentProps extends ADFRendererProps {
	offsetTop: number;
	defaultScrollRootId_DO_NOT_USE?: string;
	shouldAddDefaultScrollRootOffsetTop_DO_NOT_USE?: boolean;
	flags: FlagsStateContainer;
}

type ProviderDefinitions = {
	[name: string]: Promise<any>;
};

type MergeDataProvidersOptions = {
	intl: IntlShape;
	cloudId: string;
	userId: string | null;
	userRole?: 'admin' | 'trusted' | 'basic';
	contentId: string;
	spaceKey: string;
	flags: FlagsStateContainer;
	featureFlags: FeatureFlagsType;

	providerFactory?: ProviderFactory;
	getDataProviders?: () => ProviderFactory;
	profilecardProvider: Promise<ProfileCardProvider>;

	isExternalShare: boolean;
	hasViewUserProfilePermission: boolean;

	handleActionToggle: (objectKey: ObjectKey, state: TaskState) => void;
	handleInviteItemClick?: () => void;
	mediaToken?: string;
	emojiResourceParams?: CreateEmojiProviderParametersType;
};

const mergeDataProviders: (options: MergeDataProvidersOptions) => ProviderFactory | undefined = ({
	hasViewUserProfilePermission,
	handleActionToggle,
	handleInviteItemClick,
	getDataProviders,
	providerFactory,
	isExternalShare,
	contentId,
	spaceKey,
	cloudId,
	userId,
	userRole,
	flags,
	profilecardProvider,
	intl,
	mediaToken,
	emojiResourceParams,
}) => {
	const providers: ProviderDefinitions = {};

	if (getDataProviders) {
		Object.assign(providers, getDataProviders());
	}

	if (contentId || mediaToken) {
		providers.mediaProvider = createRendererMediaProvider({
			contentId,
			isExternalShare,
			mediaToken,
		});

		providers.taskDecisionProvider = Promise.resolve(
			new ConfluenceTaskDecisionProvider(intl, flags, contentId, handleActionToggle),
		);
	}

	if (cloudId && userId) {
		providers.mentionProvider = createMentionsConfig(
			cloudId,
			userId,
			undefined,
			undefined,
			handleInviteItemClick,
			userRole,
		)
			.then((config) => config.mentionResource)
			.catch((err) => {
				if ((process as any).env.NODE_ENV !== 'production') {
					logger.error`Failed to initialise mention resource ${err}`;
				}
				return Promise.reject();
			});
	}

	if (emojiResourceParams && cloudId) {
		providers.emojiProvider = getEmojiProvider(EmojiResource, {
			...emojiResourceParams,
			allowAnonymousAccess: true,
		});
	}

	if (cloudId) {
		// If global view user profile is enabled then user can view profile card
		if (hasViewUserProfilePermission) {
			// If Public Link, we want to disable profileCard handlers
			providers.profilecardProvider =
				isExternalShare && fg('public_links_content_id_removal')
					? Promise.resolve()
					: profilecardProvider;
		}
	}

	if (contentId && spaceKey) {
		providers.contextIdentifierProvider = Promise.resolve({
			containerId: spaceKey,
			objectId: contentId,
			product: 'confluence',
		});
	}

	providers.cardProvider = Promise.resolve(
		new EditorCardProvider(undefined, undefined, 'CONFLUENCE'),
	);

	const keys = Object.keys(providers);

	if (keys.length) {
		providerFactory = providerFactory || ProviderFactory.create({});
		for (const provider of Object.keys(providers)) {
			providerFactory.setProvider(provider as ProviderName, providers[provider]);
		}
	}

	return providerFactory;
};

const projectLinkerHandlers = {
	'com.atlassian.confluence.project-linker': () => <></>,
};

export const unsupportedContentLevelsTrackingOptions =
	(): RendererProps['unsupportedContentLevelsTracking'] =>
		fg('platform_renderer_unsupported_content_tracking')
			? UNSUPPORTED_CONTENT_THRESHOLDS
			: undefined;

export const ADFRendererComponent = memo((props: ADFRendererComponentProps) => {
	const {
		document,
		dataProviders,
		extensionHandlers = {},
		getExtensionHandlers = () => ({}),
		eventHandlers,
		appearance,
		adfStage = 'stage0',
		portal,
		onComplete,
		onRendered,
		renderWhenReady,
		allowStickyHeaders = true,
		allowPlaceholderText = false,
		isTruncatedContent,
		maxHeight,
		fadeOutHeight,
		flags,
		offsetTop,
		defaultScrollRootId_DO_NOT_USE,
		shouldAddDefaultScrollRootOffsetTop_DO_NOT_USE,
		mediaToken,
		onError,
		UNSTABLE_allowTableAlignment = true,
		UNSTABLE_allowTableResizing = true,
		...rest
	} = props;
	const intl = useIntl();

	const [isTaskToggled, setIsTaskToggled] = useState<Boolean>(false);

	const initialState = window['__SSR_SMARTLINKS__'];
	const isPageSSR = isPageSSRd();
	const storeOptions = useStoreOptions(initialState, isPageSSR);
	// Fix for HOT-99954 - Issue where embedded pages SSR SmartLinks open in same tab
	const shouldSSRsmartLinks = isPageSSR && !isEmbeddedConfluence_DO_NOT_USE();

	const { cloudId, userId, featureFlags, isLicensed, isLoggedIn, isAdminHubAIEnabled } =
		useSessionData();

	const [spaceKey] = usePageSpaceKey();
	const [pageContextContentId] = usePageContentId();
	const [pageContextContentType] = useContentType();

	const { openCoreInvites, userRole } = useContext(CoreInvitesContext);

	const [shouldRefetchContent, setShouldRefetchContent] = useState(false);

	const contentId = props.contentId || pageContextContentId;
	const contentType = props.contentType || pageContextContentType;

	const { canViewUserProfile: hasViewUserProfilePermission } = useViewUserProfile();
	const renderServerPlaceholder = useRenderServerPlaceholder();
	const taskBatcherRef = useRef(
		new TaskBatcher(
			expVal(
				'confluence_frontend_task_batching_interval',
				'value',
				DEFAULT_TASK_UPDATE_INTERVAL_MS,
			),
		),
	);
	const feedFollowData = useFeedFollow();

	// Handlers for batched task update
	const handleBatchTaskUpdate = useCallback(
		(batch: TaskAction[]) => {
			// @ts-ignore FIXME: `contentId` can be `undefined` here, and needs proper handling
			void batchTaskUpdate(contentId, batch).then(() => {
				setIsTaskToggled(false);
				setShouldRefetchContent(true);
			});
		},
		[contentId, setShouldRefetchContent],
	);

	const handleActionToggle = useCallback(
		(objectKey: ObjectKey, state: TaskState) => {
			setIsTaskToggled(true);
			taskBatcherRef.current.add({ objectKey, state });
		},
		[taskBatcherRef],
	);

	useEffect(() => {
		// there is a document check - we do not want to call registerGlobalMediaViewedListener
		// with no content
		if (document) {
			registerGlobalMediaViewedListener();
		}

		onRendered && onRendered();

		taskBatcherRef.current.onFlush(handleBatchTaskUpdate);

		return () => {
			if (document) {
				removeGlobalMediaViewedListener();
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [contentId, taskBatcherRef, handleBatchTaskUpdate]);

	useEffect(() => {
		setShouldRefetchContent(false);
		// WS-4098 - When the contentId changes or when we mount we don't need to refetch
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [contentId]);

	const handleBeforeUnload = (event: BeforeUnloadEvent) => {
		if (isTaskToggled) {
			event.preventDefault();
			event.returnValue = ''; // This triggers the alert in all browsers
		}
	};

	useEffect(() => {
		window.addEventListener('beforeunload', handleBeforeUnload);
		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isTaskToggled, contentId]);

	// Setting up emoji resources for ssr and data providers
	const emojiResourceParams = useMemo(() => {
		if (cloudId) {
			return createEmojiProviderParameters({
				cloudId,
				userId,
				isLicensed,
				isLoggedIn,
			});
		}
	}, [cloudId, userId, isLicensed, isLoggedIn]);

	const emojiResourceConfig = useMemo(() => {
		if (emojiResourceParams) {
			return getEmojiConfig(
				emojiResourceParams.cloudId,
				emojiResourceParams.userId,
				emojiResourceParams.disableUpload,
				emojiResourceParams.emojiSSRConfig,
			);
		}
	}, [emojiResourceParams]);

	const { isExternalShareRequest } = useContext(ExternalShareContext);

	const { push } = useRouteActions();

	let mergedExtensionHandlers = extensionHandlers;
	if (getExtensionHandlers) {
		mergedExtensionHandlers = Object.assign(getExtensionHandlers(), extensionHandlers);
	}

	mergedExtensionHandlers = {
		...mergedExtensionHandlers,
		...getRendererCustomSitesExtensionHandlers({ contentId }),
		...getRendererSmartButtonExtensionHandlers({
			contentId,
			contentType,
			cloudId,
		}),
		...projectLinkerHandlers,
	};

	const mediaSSR = useMediaSSRModeAndAuthConfig({
		contentId,
		isExternalShare: isExternalShareRequest,
		mediaToken,
	});

	// -- Matches fabric-feature-flags.ts

	const isStickyHeadersEnabled = allowStickyHeaders;

	const errorHandler = (error: Error) => {
		if (process.env.REACT_SSR) {
			getMonitoringClient().submitError(error, {
				attribution: Attribution.BACKBONE,
			});
		}
		if (onError) {
			onError(error);
		}
	};

	const { shimAdfUrls } = useNavigationPolicy();

	const editorFeatureFlags = useEditorFeatureFlags();

	const profilecardProvider = useProfilecardProvider({
		push,
		userId,
		cloudId,
		featureFlags,
	});

	const linkingPlatformFeatureFlags = useSmartCardProviderFeatureFlags('renderer');

	const onRenderComplete = useCallback(
		(stats: RenderOutputStat) => {
			if (onComplete) {
				onComplete(stats);
			}
		},
		[onComplete],
	);

	// ----------------------------------

	if (!document) {
		return null;
	}

	const mergedDataProviders = mergeDataProviders({
		// @ts-ignore FIXME: `contentId` can be `undefined` here, and needs proper handling
		contentId,
		// @ts-ignore FIXME: `spaceKey` can be `undefined` here, and needs proper handling
		spaceKey,
		cloudId,
		userId,
		flags,
		featureFlags,
		push,
		intl,
		providerFactory: dataProviders,
		profilecardProvider,
		isExternalShare: isExternalShareRequest,
		handleActionToggle,
		hasViewUserProfilePermission,
		handleInviteItemClick: openCoreInvites,
		userRole,
		mediaToken,
		emojiResourceParams,
	});

	const finalDocument = shimAdfUrls(document) as DocNode;

	return (
		<SmartCardProvider
			{...getSmartCardRenderers()}
			{...storeOptions}
			featureFlags={linkingPlatformFeatureFlags}
			isAdminHubAIEnabled={isAdminHubAIEnabled}
			product="CONFLUENCE"
		>
			{(appearance === 'full-page' || appearance === 'full-width') && (
				<PageLoadMark data-testid="pageLoadMarkTestId" name="onBeforeAkRenderer" />
			)}
			<RendererAnalyticsListener
				featureFlags={featureFlags}
				// @ts-ignore FIXME: `contentId` can be `undefined` here, and needs proper handling
				objectId={contentId}
				objectType={contentType}
			>
				<AkRenderer
					// This flag is used for fixing table width in SSR mode and for hydration
					enableSsrInlineScripts={Boolean(process.env.REACT_SSR)}
					// include renderer inline script from above on first render to prevent hydration mismatch
					noOpSSRInlineScript={renderServerPlaceholder}
					adfStage={adfStage}
					document={finalDocument}
					appearance={appearance}
					stickyHeaders={
						isStickyHeadersEnabled && {
							offsetTop,
							defaultScrollRootId_DO_NOT_USE,
							shouldAddDefaultScrollRootOffsetTop_DO_NOT_USE,
						}
					}
					extensionHandlers={mergedExtensionHandlers}
					eventHandlers={eventHandlers}
					featureFlags={editorFeatureFlags}
					dataProviders={mergedDataProviders}
					portal={portal}
					onComplete={onRenderComplete}
					allowColumnSorting
					allowHeadingAnchorLinks={{
						allowNestedHeaderLinks: true,
						activeHeadingId: props.queryHash,
					}}
					allowAltTextOnImages
					media={{
						allowLinking: true,
						allowCaptions: true,
						featureFlags: getMediaFeatureFlags(featureFlags),
						ssr: mediaSSR,
					}}
					emojiResourceConfig={emojiResourceConfig}
					allowCopyToClipboard
					allowWrapCodeBlock
					{...rest}
					useSpecBasedValidator
					truncated={isTruncatedContent}
					maxHeight={maxHeight}
					fadeOutHeight={fadeOutHeight}
					analyticsEventSeverityTracking={ANALYTICS_EVENT_SEVERITY_TRACKING}
					unsupportedContentLevelsTracking={unsupportedContentLevelsTrackingOptions()}
					allowPlaceholderText={allowPlaceholderText}
					allowCustomPanels
					smartLinks={{
						ssr: shouldSSRsmartLinks,
						hideHoverPreview: false,
					}}
					onError={errorHandler}
					UNSTABLE_allowTableAlignment={UNSTABLE_allowTableAlignment}
					UNSTABLE_allowTableResizing={UNSTABLE_allowTableResizing}
				/>
				{renderWhenReady && renderWhenReady()}
			</RendererAnalyticsListener>
			<ContentRefetchHandler contentId={contentId} shouldRefetch={shouldRefetchContent} />
			<FeedFollowErrorDisplay
				feedConfigError={feedFollowData.feedConfigError}
				mutationError={feedFollowData.mutationError}
				showErrorFlag
			/>
		</SmartCardProvider>
	);
});
